import { useState, useEffect } from "react";
import { Link, useOutletContext } from 'react-router-dom';
import transport from './transport.js';
import ArticlePage from './ArticlePage';

export default function NewsPage(props) {
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(null);
  const [ news, setNews ] = useState([]);

  const { baseURL, authenticate, userInfo } = useOutletContext();

  useEffect(() => {
    document.title = props.title ? props.title + " | Coding Club" : "Coding Club";
    authenticate();
  }, []);

  useEffect(() => {
    transport.get(`${baseURL}/api/news`)
    .then(res => {
      setNews(res.data);
      setLoading(false);
    })
    .catch(e => {
      console.log(e);
      setError(e);
      setLoading(false);
    });
  }, []);
  
	const newsArea = () => (
		<div>
      <div className="page-header">
        <h2>Uutiset</h2>
        { userInfo.canCreateNews ?
          <button className="btn btn-with-link">
            <Link to="/lisaa-uutinen">Lisää uutinen</Link>
          </button>
        : "" }
      </div>
      <div className="news-index">
        {news.sort((a2,a1) => new Date(a1.publishDate) - new Date(a2.publishDate)).map(item => newsItem(item))}
      </div>
		</div>
  );

	const newsItem = ({id, title, content}) => (
    <Link
        key={id} 
        to={`/uutiset/${encodeURIComponent(title)}/${id}`}
        className="article-button-link">
      <article className="article-button">
        <h3>{title}</h3>
        <p>{content}</p>
      </article>
    </Link>
	)
  
  return(
    <div className="content">
      {newsArea()}
    </div>
	);
}
