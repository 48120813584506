import { useState, useEffect } from "react";
import { Link, useOutletContext } from 'react-router-dom'
import transport from './transport.js';

export default function ProjectsPage(props) {
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(null);
  const [ projects, setProjects ] = useState([]);

  const { baseURL, authenticate, userInfo } = useOutletContext();
  
  useEffect(() => {
    transport.get(`${baseURL}/api/projects`)
    .then(res => {
      setProjects(res.data);
    })
    .catch(e => {
      console.log(e);
      setError(e);
    });
  }, []);
  
  useEffect(() => {
    document.title = props.title ? props.title + " | Coding Club" : "Coding Club";
    authenticate();
  }, []);

  const projectLink = ({id, title, author, content}) => (
    <Link key={id}
        to={`/projektit/${encodeURIComponent(title)}/${id}`}
        className="article-button-link">
      <article className="article-button">
        <h3>{title}</h3>
        <p>Tekijä: {author}</p>
      </article>
    </Link>
  );

  return(
    <div className="content">
      <div className="page-header">
        <h2>Projektit</h2>
        { userInfo.canCreateProjects ?
          <button className="btn btn-with-link">
              <Link to="/lisaa-projekti">Lisää projekti</Link>
          </button>
        : "" }
      </div>
      {projects.sort((a2,a1) => new Date(a1.publishDate) - new Date(a2.publishDate)).map(p => projectLink(p))}
  </div>)
}
