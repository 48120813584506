import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function ElectronicsClubPage(props) {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/sivut/elektroniikkakerho");
  }, []);

  return(
    <div className="content">
    </div>
  );
}
