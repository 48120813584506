import React from "react";
import { useState, useEffect } from 'react';
import transport from './transport.js'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import css from './UsersPage.module.css';

export default function UsersPage(props) {      
  const [ users, setUsers ] = useState([]);
  const [ search, setSearch ] = useState("");

  const { authenticate, baseURL, userInfo, } = useOutletContext();

  const navigate = useNavigate();

  useEffect(() => {
    document.title = props.title ? props.title + " | Coding Club" : "Coding Club";
    getUsers();
    authenticate();
  }, []);

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  const getUsers = () => {
    let p = transport.get(`${baseURL}/api/users`)
    p.then(res => {
      setUsers(res.data);
    }) 
    .catch(error => {
      if (error.response.status === 403) {
        navigate("/");
      }
      console.log(error)
    });
    return p;
  };

  const infoRow = (label, data) => (
    <tr><td>{label}:</td><td>{data}</td></tr>
  );

  const editUser = (id) => {
    navigate(`/kayttajat/${id}/muokkaa`);
  };

  const editButton = (id) => (
    <button
      className="edit-user btn"
      onClick={() => editUser(id)}
    >
      <FontAwesomeIcon icon={faPen} />
    </button>
  );

  const userDetails = ({_id, name, username, email, dateCreated, role}) => (
    <details
      key={_id}
      className={css.userInfoBox}
    >
      <summary>
        <span>
          <span className={css.name}>{name}</span>|
          <span className={css.username}>{username}</span>
        </span>
        {editButton(_id)}
      </summary>
      <div className="details-content">
        <table><tbody>
        {infoRow("ID", _id)}
        {infoRow("Nimi", name)}
        {infoRow("Käyttäjänimi", username)}
        {infoRow("Sähköposti", email)}
        {infoRow("Rooli", role)}
        {infoRow("Luotu", new Date(dateCreated).toLocaleDateString("fi"))}
        </tbody></table>
      </div>
    </details>
  );

  const filter = (user) => {
    return (
      user.name.toLowerCase().includes(search) ||
      user.username.toLowerCase().includes(search) ||
      user.email.toLowerCase().includes(search) ||
      user.role.toLowerCase().includes(search) ||
      user.dateCreated.toLowerCase().includes(search)
    )
  };

  const usersList = () => (
    <div className={css.usersList}>
      {users.filter(filter).map(user => userDetails(user))}
    </div>
  );

  return(
    <div className="content">
      <div className="page-header">
        <h2>Käyttäjät</h2>
        <div className="search">
          <FontAwesomeIcon icon={faMagnifyingGlass} />
          <input
            placeholder="Hae"
            value={search} 
            onChange={handleInputChange}
          />
        </div>
      </div>
      { userInfo.canViewUsers && usersList() }
    </div>
  );
}
