import { useState, useEffect } from "react";
import { Link, useOutletContext } from 'react-router-dom';
import transport from './transport.js';
import ArticlePage from './ArticlePage';

const RecentNewsArea = ({baseURL, userInfo}) => {
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(null);
  const [ news, setNews ] = useState([]);
  
  useEffect(() => {
    transport.get(`${baseURL}/api/recent-news`)
    .then(res => {
      setNews(res.data);
      setLoading(false);
    })
    .catch(e => {
      console.log(e);
      setError(e);
      setLoading(false);
    });
  }, []);

	const newsItem = ({id, title, content}) => (
    <Link
        key={id} 
        to={`/uutiset/${encodeURIComponent(title)}/${id}`}
        className="article-button-link">
      <article className="article-button">
        <h3>{title}</h3>
        <p>{content}</p>
      </article>
    </Link>
	);

	const newsArea = () => (
		<div>
      <div className="page-header">
        <h2>Ajankohtaista</h2>
        { userInfo.canCreateNews ?
          <button className="btn btn-with-link">
            <Link to="/lisaa-uutinen">Lisää uutinen</Link>
          </button>
        : "" }
      </div>
      <div className="news-index">
        {news
          .map(item => newsItem(item))}
      </div>
		</div>
  );

  return newsArea();
};

export default function Frontpage(props) {
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(null);
  const [ frontPageArticle, setFrontpageArticle ] = useState(null);
  const [ frontPageChoice, setFrontpageChoice ] = useState("");

  const {baseURL, loggedIn, menuPages, userInfo, authenticate} = useOutletContext();

  useEffect(() => {
    document.title = props.title ? props.title + " | Coding Club" : "Coding Club";
    authenticate();
  }, []);

  useEffect(() => {
    getFrontpage();
  }, [userInfo]);

  const getFrontpage = () => {
    transport.get(`${baseURL}/api/frontpage`)
    .then(res => {
      setFrontpageArticle(res.data);
      if (userInfo.canSetFrontpage) {
        setFrontpageChoice(res.data.id);
      }
    })
    .catch(e => {
      if (e.response && e.response.status === 404) {
        return;
      }
      setError(e);
      console.log(e);
    });
  };

  const setFrontpage = () => {
    transport.post(`${baseURL}/api/set-frontpage`, {
      id: frontPageChoice || null
    })
    .then(res => {
      setFrontpageArticle(res.data);
      getFrontpage();
    })
    .catch(e => {
      console.log(e);
    })
  };

  const frontPageSelector = () => {
    if (!loggedIn || !userInfo.canSetFrontpage) return "";
    return(<div>
      <h3>Valitse etusivu</h3>
      <select
        value={frontPageChoice}
        onChange={(e) => {
          setFrontpageChoice(e.target.value);
        }}
      >
        <option value={""}>Ei etusivua</option>
        {menuPages.map(page => 
          <option value={page.id} key={page.id}>{page.title}</option>)
        }
      </select>
      <br/>
      <br/>
      <button className="btn" onClick={setFrontpage}>Tallenna</button>
    </div>);
  };
    
  const frontPage = () => {
    if (!frontPageArticle) return "";
    return(
      <ArticlePage 
        baseURL={baseURL}
        article={frontPageArticle}
        type="page"
        embed
      />
    );
  };
  
  return(
    <div className="content">
      {frontPageSelector()}
      {frontPage()}
      <RecentNewsArea 
        baseURL={baseURL}
        userInfo={userInfo}
      />
    </div>
	);
}
