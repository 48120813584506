import { useMemo, useEffect, useState } from "react";
import DOMPurify from 'dompurify'
import showdown from 'showdown'
import moment from 'moment'
import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import transport from './transport.js'

export default function ArticlePage({ preview, embed, type, ...props }) {
  const { id, pagePath } = useParams();
  const navigate = useNavigate();

  const [ article, setArticle ] = useState({});
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(null);

  const { baseURL, loggedIn, getMenuPages, userInfo } = useOutletContext();

  const converter = useMemo(() => new showdown.Converter());

  // get article
  useEffect(() => {
    setLoading(true);
    setError(null);

    if (preview || embed) {
      setArticle(props.article);
      setLoading(false);
      return;
    }

    const articleTypeToPath = {
      news: "news",
      project: "projects",
      page: "pages"
    };
    const path = articleTypeToPath[type];

    transport.get(`${baseURL}/api/${path}/${id || pagePath}`)
    .then(res => {
      setArticle(res.data);
      setLoading(false);
    })
    .catch(e => {
      setError(e);
      setLoading(false);
      console.log(e);
    })
  }, [props.article, id, pagePath]);

  // change document.title
  useEffect(() => {
    if (!(preview || embed) && article.title) {
      document.title = article.title + " | Coding Club";
    }
  }, [loading]);

  const editArticle = () => {
    let path;
    if (type === "news") path = "uutiset"
    else if (type === "project") path = "projektit";
    else if (type === "page") path = "sivut";
    navigate(`/${path}/${article.id}/muokkaa`);
  }

  const deleteArticle = () => {
    if (!loggedIn) {
      setError({message: "Et ole kirjautunut."});
      return;
    }

    if (!window.confirm(`Poistetaanko "${article.title}"?`)) {
      return;
    }

    transport.delete(`${baseURL}/api/delete-article/`, 
      { data: {
          type: type,
          id: article.id
      } }
    )
    .then(res => {
        if (!res.data.success) {
          setError({message: "Jotain meni pieleen."});
        } else {
          if (type === "page") getMenuPages();
          let redirect = "/";
          if (type === "project") redirect = "/projektit";
          navigate(redirect);
        }
    })
    .catch(e => {
      setError(e);
      console.log(e);
    });
  }

  const errors = () => {
    if (!error) return;

    return(
      <div className="errors">
        <p>{error.message}</p>
      </div>
    );
  }

  let canUpdate = loggedIn && (userInfo.canUpdateAny[type] || (userInfo.id === article.creator));
  let canDelete = loggedIn && (userInfo.canDeleteAny[type] || (userInfo.id === article.creator));

  return(
    <div className={`content ${embed ? "embed" : ""}`}>
      {errors()}
		  <article>
        <div className="page-header">
          <h2>{article.title}</h2>
          {(!preview && canDelete) && <button onClick={deleteArticle} className="btn">Poista</button>}
          {(!preview && canUpdate) && <button onClick={editArticle} className="btn">Muokkaa</button>}
          {type !== "page" && <p className="article-date">{moment(article.publishDate).format("YYYY-MM-DD")}</p>}
          {article.author && <p className="article-author">{article.author}</p>}
        </div>
        {/*<div className="article-more-info">
          <p>{article?.lastModified}</p>
        </div>*/}
        <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(converter.makeHtml(article.content))}}>
        </div>
		  </article>
    </div>
  );
}
