import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import FrontPage from "./FrontPage.js";
import ProjectsPage from "./ProjectsPage.js";
import NewsPage from "./NewsPage.js";
import GamesPage from "./GamesPage.js";
import RegisterPage from "./RegisterPage.js";
import LoginPage from "./LoginPage.js";
import SettingsPage from "./SettingsPage.js";
import ArticlePage from "./ArticlePage.js";
import EditorPage from "./EditorPage.js";
import UsersPage from "./UsersPage.js";
import UserPage from "./UserPage.js";
import ElectronicsClubPage from './ElectronicsClubPage';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <FrontPage title="Etusivu" />,
      },
      {
        path: "/uutiset",
        element: <NewsPage title="Uutiset" />
      },
      {
        path: "/projektit",
        element: <ProjectsPage title="Projektit" />,
      },
      {
        path: "/elektroniikka",
        element: <ElectronicsClubPage />
      },
      {
        path: "/pelit",
        element: <GamesPage title="Pelit" />
      },
      {
        path: "/asetukset",
        element: <SettingsPage title="Asetukset" />
      },
      {
        path: "/kirjaudu",
        element: <LoginPage title="Kirjaudu sisään" />
      },
      {
        path: "/luo-kayttaja",
        element: <RegisterPage title="Luo käyttäjä" />
      },
      {
        path: "/kayttajat",
        element: <UsersPage title="Käyttäjät" />
      },
      {
        path: "/kayttajat/:id/muokkaa",
        element: <UserPage />
      },
      {
        path: "/uutiset/:title/:id",
        element: <ArticlePage
          type="news"
        />
      },
      {
        path: "/projektit/:title/:id",
        element: <ArticlePage
          type="project"
        />
      },
      {
        path: "/sivut/:pagePath",
        element: <ArticlePage
          type="page"
        />
      },
      {
        path: "/sivut/:id/muokkaa",
        element: <EditorPage
          type="page"
          editing={true}
          title="Muokkaa sivua"
        />
      },
      {
        path: "/uutiset/:id/muokkaa",
        element: <EditorPage
          type="news"
          editing={true}
          title="Muokkaa uutista"
        />
      },
      {
        path: "/projektit/:id/muokkaa",
        element: <EditorPage
          type="project"
          editing={true}
          title="Muokkaa projektia"
        />
      },
      {
        path: "/lisaa-uutinen",
        element: <EditorPage
          type="news"
          title="Lisää uutinen"
        />
      },
      {
        path: "/lisaa-projekti",
        element: <EditorPage
          type="project"
          title="Lisää projekti"
        />
      },
      {
        path: "/lisaa-sivu",
        element: <EditorPage
          type="page"
          title="Lisää sivu"
        />
      },
    ]
  }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
