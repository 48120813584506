import TicTacToe from "./TicTacToe.js";
import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

export default function GamesPage(props) {
  const { baseURL } = useOutletContext();

  useEffect(() => {
    document.title = props.title ? props.title + " | Coding Club" : "Coding Club";
  }, []);

  return(
    <div className="content">
      <h2>Pelit</h2>
			<h3>Ristinolla</h3>
			<TicTacToe baseURL = {baseURL}/>
    </div>
  );
}
