import React from "react";
import { useState, useEffect } from 'react';
import transport from './transport.js'
import ArticlePage from './ArticlePage'
import { useParams, useNavigate, useOutletContext } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

export default function EditorPage(props) {
  const { id } = useParams();

  const { baseURL, getMenuPages, notify, loggedIn, userInfo } = useOutletContext();

  const [ article, setArticle ] = useState({
    title: "",
    author: props.type === "page" ? undefined : userInfo.name,
    content: "",
    date: new Date(),
  });

  const [ errors, setErrors ] = useState([]);
  const [ redirect, setRedirect ] = useState("");
	
  const [ selectedFile, setSelectedFile ] = useState(null);
  const [ images, setImages ] = useState([]);
  const [ loading, setLoading ] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    document.title = props.title ? props.title + " | Coding Club" : "Coding Club";
    if (id) {
      getArticle();
    } else {
      setLoading(false);
    }
    getMyImages();
  }, [id]);

  const getArticle = () => {
    let type;
    if (props.type === "news") type = "news"
    else if (props.type === "project") type = "projects"
    else if (props.type === "page") type = "pages"

    transport.get(`${baseURL}/api/${type}/${id}`)
    .then(res => {
      let a = res.data;
      const setFields = ["title", "author", "content", "path"];
      const newArticle = {...article};
      setFields.forEach(field => {
        newArticle[field] = res.data[field];
      });
      setArticle(newArticle);
      setLoading(false);
    })
    .catch(e => {
      console.log(e);
    });
  };

  const handleInputChange = (e, thing) => {
    setErrors([]);
    const newArticle = {...article};
    newArticle[thing] = e.target.value;
    setArticle(newArticle);
  };

  /* lets user insert tabs into text box */
  const handleContentKeyDown = (e) => {
    if (e.keyCode === 9) {
      e.preventDefault();
      const el = e.target
      const [start, end] = [el.selectionStart, el.selectionEnd];
      el.setRangeText("\t", start, end, "end");
    }
  }

  const startDrag = (event) => {
    event.dataTransfer.setData("drag-item", event.target.src)
  }

  const handleDrop = (event) => {
    event.preventDefault();
    let link = event.dataTransfer.getData("drag-item");
    const newArticle = {...article};
    newArticle.content = article.content + `![kuva](${link})\n`;
    setArticle(newArticle);
  }

  const publishArticle = () => {
    const reqArticle = {
      title: article.title,
      author: article.author,
      path: article.path === "" ? undefined : article.path,
      content: article.content,
      type: props.type,
      id: id
    }

    if (!reqArticle.title || (props.type !== "page" && !reqArticle.author) || !reqArticle.content) {
      setErrors([{msg:"Kaikki kentät täytyy täyttää"}]);
      return;
    }

    let url = "new-article";
    if (props.editing) { url = "update-article"; }

    transport
      .post(`${baseURL}/api/${url}`, reqArticle)
      .then(response => {
        if (!response.data.success) {
          setErrors([{msg:"Jotain meni pieleen"}]);
          return;
        }
        if (props.type === "news") navigate("/")
        else if (props.type === "project") navigate("/projektit")
        else if (props.type === "page") {
          getMenuPages();
          navigate("/")
        }
      })
      .catch(e => {
        console.log(e)
      });
  };

  const onFileChange = event => {
    setErrors([]);
    setSelectedFile(event.target.files[0]);
  };

  const getMyImages = () => {
    transport.get(`${baseURL}/api/my-images`)
    .then(res => {
      setImages(res.data.images);
    })
    .catch(e => console.log(e))
  };

  const uploadImage = (e) => {
    e.preventDefault()
    setErrors([]);

    if (selectedFile === null) {
      setErrors([{msg: "Ei valittua tiedostoa"}]);
      return;
    }

    let fd = new FormData()
    fd.append('file', selectedFile, selectedFile.name)
    transport.post(`${baseURL}/api/upload-image`, fd)
    .then(res => {
      if (!res.data.success) {
        setErrors([{msg: "Jotain meni pieleen."}]);
        return;
      }
      getMyImages();
      setSelectedFile(null);
    })
    .catch(e => {
      console.log(e);
    });
  };

  const deleteImage = (img) => {
    if (!window.confirm(`Poistetaanko kuva ${img.fileName}?\n\nJos kuvaa on käytetty uutisessa tai projektissa, niin kannattaa poistaa se sieltä ennen kokonaan poistamista (muuten kuvaa ei enää näy).`)) return;

    transport.delete(`${baseURL}/api/delete-image`,{data:{id: img._id}})
    .then(res => {
      if (!res.data.success) {
        notify("Jotain meni pieleen", "Kuvan poistaminen ei onnistunut.");
        return;
      } else {
        setImages(images.filter(i => i._id != img._id));
      }
    })
    .catch(e => {
      console.log(e)
    });
  };

  const littleImage = (img) => (
    <div key={img._id} className="littleimage">
      <div className="delete-image-button-container">
        <button className="small-icon-btn x-btn btn" onClick={() => deleteImage(img)}>
        <FontAwesomeIcon icon={faXmark} />
      </button></div>
      <img alt=""
        onDragStart={startDrag}
        src={`${baseURL}/api/images/${img._id}`}/>
      <span>{img.fileName}</span>
    </div>
  );

  const myImages = () => {
    return(
      <details className="add-image">
        <summary><h3>Lisää kuva</h3></summary>
        <div className="details-content">
          <label>Omat kuvat</label>
          <div className="my-images">
            {images.length > 0 ?
              images.map((img, i) => littleImage(img))
            : <span className="noimages-text">Ei kuvia</span>}
          </div>
          <form onSubmit={uploadImage} encType="multipart/form-data">
            <input onChange={onFileChange} className="file-input" type="file"/>
            <button type="submit" className="btn">Lataa kuva</button>
          </form>
        </div>
      </details>
    );
  };

  const errorsList = () => {
    if (errors.length === 0) return;
    return(<div className="errors">
      {errors.map(error =>
        <p key={error.msg}>{error.msg}</p>
      )}
    </div>);
  };

  if (!loggedIn) navigate("/");
    
  if (loading) return "";

  return(<div>
    <div className="content">
      <div className="page-header">
        <h2>{props.title}</h2>
        <button onClick={publishArticle} className="btn">
          {props.editing ? "Tallenna" : "Julkaise"}
        </button>
      </div>
      {errorsList()}
      <p className="article-editor-option"><label>Otsikko</label>
      <input value={article.title} onChange={e => handleInputChange(e, "title")}></input></p>
      { props.type !== "page" &&
        <>
        <p className="article-editor-option"><label>Tekijä</label>
        <input value={article.author} onChange={e => handleInputChange(e, "author")}></input></p>
        </>
      }
      { props.type === "page" &&
        <>
        <p className="article-editor-option"><label>Osoite</label>
        <span>{"https://codingclub.utu.fi/sivut/"}</span>
      <input value={article.path} onChange={e => handleInputChange(e, "path")}></input></p>
      </>}
      {myImages()}
      <label>Sisältö</label>
      <textarea className="article-content-editor"
        onDrop={handleDrop}
        onChange={e => handleInputChange(e, "content")}
        onKeyDown={e => handleContentKeyDown(e)}
        value={article.content}
        type="text"></textarea>
      <h3>Esikatselu</h3>
      <hr/>
    </div>
    <ArticlePage
      article={{
        title: article.title,
        content: article.content,
        date: article.date,
        author: article.author
      }}
    preview={true} />
  </div>);
}
