import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import css from './Notification.module.css';

export default function Notification({ title, text, deleteFunction }) {
  return(<div className={css.notification}>
    <div className={css.notificationHeader}>
      <h4>{title}</h4>
      <button className="btn small-icon-btn" onClick={deleteFunction}>
        <FontAwesomeIcon icon={faXmark} />
      </button>
    </div>
    <p>{text}</p>
  </div>)
}
