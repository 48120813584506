import { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from 'react-router-dom';
import transport from "./transport";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'

export default function RegisterPage(props) {
	
  const [ errors, setErrors ] = useState([]);
  const [ buttonDisabled, setButtonDisabled ] = useState(false);
  const [ userInfoForm, setUserInfoForm ] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
    password2: ""
  });

  const navigate = useNavigate();

  const { notify, login, baseURL } = useOutletContext();

  useEffect(() => {
    document.title = props.title ? props.title + " | Coding Club" : "Coding Club"
  }, []);
	
  const fields = [
    { field: "name", label: "Nimi:", required: true },
    { field: "username", label: "Käyttäjänimi:", required: true, info: "Käytetään kirjautumiseen" },
    { field: "email", label: "Sähköpostiosoite:", required: false, },
    { field: "password", label: "Salasana:", type: "password", required: true },
    { field: "password2", label: "Vahvista salasana:", type: "password", required: true }
  ];

  const register = (event) => {
    event.preventDefault();

    transport.post(`${baseURL}/api/register`, userInfoForm)
      .then(response => {
        if (response.data.success) {
          notify("Käyttäjä luotu","");
          
          const loginCredentials = {
            username: userInfoForm.username,
            password: userInfoForm.password
          };

          login(loginCredentials)
          .then(res => {
            if (res.data.authenticated) {
              navigate("/");
            }
          })
          .catch(e => console.log(e));
          return;
        }
        setErrors(response.data.errors);
      })
      .catch(error => console.log(error));
    setButtonDisabled(true);
    setTimeout(() => {setButtonDisabled(false)}, 700);
  }

  const errorsDiv = () => {
    if (errors.length === 0) return;
    return(
      <div className="errors">
        {errors.map((e, i) => <p key={i}>{e.msg}</p>)}
      </div>
    );
  };

  const handleInputChange = (e, field) => {
    const newUserInfoForm = {...userInfoForm};
    newUserInfoForm[field] = e.target.value;
    setUserInfoForm(newUserInfoForm);
  };

  const registerForm = () => (
    <form className="registerform" onSubmit={register}>
      <p className="requiredinput requiredinputinfo">Pakollinen tieto</p>

      {fields.map(f => (
        <div className={"registerform-field " + (f.required ? "requiredinput" : "")} key={f.field}>
          <label>{f.label}</label>
          {f.info && <><FontAwesomeIcon className="info-icon" icon={faCircleInfo} /><span className="info-text">{f.info}</span></>}
          <br/>
          <input
            type={f.type || "text"}
            name={f.field === "username" ? "username" : undefined}
            value={userInfoForm[f.field]}
            onChange={e => handleInputChange(e, f.field)}
          />
        </div>
      ))}

      <button type="submit" className="btn" disabled={buttonDisabled}>Luo käyttäjä</button>
    </form>
  );

	const registerPage = () => (
    <div className="content">
      <h2>Luo käyttäjä</h2>
      {errorsDiv()}
      {registerForm()}
    </div>
	);

  return(
    registerPage()
  );
}
