import { useState, useEffect } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';

export default function LoginPage(props) {   
	
  const [ errorMessage, setErrorMessage ] = useState("");
  const [ wrongCount, setWrongCount ] = useState(0);
  const [ buttonDisabled, setButtonDisabled ] = useState(false);
  const [ username, setUsername ] = useState("");
  const [ password, setPassword ] = useState("");

  const navigate = useNavigate();

  const context = useOutletContext();

  useEffect(() => {
    document.title = props.title ? props.title + " | Coding Club" : "Coding Club"
  }, []);

  const login = (event) => {
    event.preventDefault()

    setErrorMessage("");
    setButtonDisabled(true);

    setTimeout(() => {
      setButtonDisabled(false);
    }, 700);

    const loginForm = {
      username: username,
      password: password,
    };

    if (!loginForm.username || !loginForm.password) {
      setErrorMessage("Käyttäjänimi tai salasana puuttuu");
      return;
    }

    let cooldownStart = localStorage.getItem("loginCooldownStart")
    let timeSinceStart = Date.parse(new Date())-cooldownStart
    if (cooldownStart && timeSinceStart < 1000*60*3) {
      let minutesLeft = Math.ceil(3 - timeSinceStart/60000)
      setErrorMessage(`Käyttäjänimi tai salasana väärin. Voit yrittää ${minutesLeft}min päästä uudelleen`);
      return;
    }
    
    context.login(loginForm)
    .then(res => {
      navigate("/");
    })
    .catch(e => {
      let oldWrongCount = wrongCount;
      let errorMessage = "Käyttäjänimi tai salasana väärin"
      if (oldWrongCount + 1 === 3) {
        localStorage.setItem("loginCooldownStart", Date.parse(new Date()))
        setErrorMessage("Käyttäjänimi tai salasana väärin. Voit yrittää 3min päästä uudelleen");
        setWrongCount(0);
      } else {
        setErrorMessage(errorMessage);
        setWrongCount(oldWrongCount + 1);
      }
    });
  }

  const errors = () => { 
    if (!errorMessage) {
        return;
    }
    return(
      <div className="errors">
        <p>{errorMessage}</p>
      </div>
    );
  };
	
  const loginForm = () => {
    return(
      <form className="loginform" onSubmit={login}>
        <p>
          <label>Käyttäjänimi:</label><br/>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </p>
        <p>
          <label>Salasana:</label><br/>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </p>
        <button className="btn" disabled={buttonDisabled} type="submit">Kirjaudu</button>
      </form>
    );
  };

	const loginPage = () => {
		return(
			<div className="content">
				<h2>Kirjaudu</h2>
        {errors()}
        {loginForm()}
        <p><Link to="/luo-kayttaja">Luo uusi käyttäjä</Link></p>
			</div>
		);
	}

  return(   
      loginPage()
  );
}
