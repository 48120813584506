import { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import transport from "./transport"
import headerCSS from './components/Header.module.css';

const PasswordField = ({label, field, value, onChange}) => (
  <p className="setting">
    <label>{label}</label>
    <input
      type="password"
      value={value}
      onChange={onChange}
    ></input>
  </p>
);

const ChangePasswordForm = (props) => {
  const [ passwords, setPasswords ] = useState({
    oldPassword: "",
    newPassword: "",
    newPassword2: ""
  });

  const [ errors, setErrors ] = useState([]);
  const [ successMessage, setSuccessMessage ] = useState("");

  const handleInputChange = (event, field) => {
    const newPasswords = {...passwords};
    newPasswords[field] = event.target.value;
    setPasswords(newPasswords);
  };

  const changePassword = (event) => {
    event.preventDefault();
    
    setErrors([]);
    setSuccessMessage("");

    const passwordForm = {
      id: props.userInfo.id,
      ...passwords
    };

    transport.post(`${props.baseURL}/api/change-password`, passwordForm)
      .then(response => {
        if (response.data.success) {
          setSuccessMessage("Salasana vaihdettu");
        } else {
          setErrors(response.data.errors);
        }
      })
      .catch(e => {
        console.log(e)
      });
  };

  const errorsDiv = () => {
    if (errors.length === 0) return;
    return(<div className="errors">
      {errors.map(error =>
        <p key={error.msg}>{error.msg}</p>
      )}
    </div>)
  };

  const successMessageDiv = () => {
    if (!successMessage) return;
    return(<div className="success">
      <p>{successMessage}</p>
    </div>)
  };

  const passwordFields = [
    {name: 'oldPassword', label: 'Vanha salasana:'},
    {name: 'newPassword', label: 'Uusi salasana:'},
    {name: 'newPassword2', label: 'Toista uusi salasana:'},
  ];

  return(
    <details>
      <summary>
        <h3>Vaihda salasana</h3>
      </summary>
      <div className="details-content">
        {successMessageDiv()}
        {errorsDiv()}
        <form className="changepasswordform" onSubmit={changePassword}>
          {passwordFields.map(field => (
            <PasswordField
              key={field.name}
              label={field.label}
              field={field.name}
              value={passwords[field.name]}
              onChange={e => handleInputChange(e, field.name)}
            />
          ))}
          <button className="btn" type="submit">Vaihda salasana</button>
        </form>
      </div>
    </details>
  );
}

const SettingInput = ({value, onChange}) => (
  <input
    onChange={onChange}
    value={value}>
  </input>
);

const Setting = ({editing, label, type, newValue, value, onChange}) => (
  <p className="setting" id={`settings-${type}`}>
    <label>{label}:</label>
    {editing ?
      <SettingInput value={newValue} onChange={onChange} />
    :
      <span className="settings-info">{value}</span>
    }
  </p>
);

const UserInfoSettings = (props) => {
  const [ editing, setEditing ] = useState(false);

  const [ newUserInfo, setNewUserInfo ] = useState({
    name: "",
    username: "",
    email: ""
  });

  const [ infoErrors, setInfoErrors ] = useState([]);
  const [ errorMessage, setErrorMessage ] = useState(null);

  useEffect(() => {
    setNewUserInfo({
      name: props.userInfo.name,
      username: props.userInfo.username,
      email: props.userInfo.email
    });
  }, []);

  const cancelButton = (type) => (
    <button
      onClick={() => setEditing(false)} 
      className="editbutton btn">
        Peruuta
    </button>
  );

  const editButton = (type) => (
    <button 
      onClick={() => setEditing(true)} 
      className="editbutton btn">Muokkaa</button>
  );

  const handleSettingChange = (event, type) => {
    const newNewUserInfo = {...newUserInfo};
    newNewUserInfo[type] = event.target.value;
    setNewUserInfo(newNewUserInfo);
  };

  const saveSettings = () => {
    if (!newUserInfo.name.trim() || !newUserInfo.username.trim() ) {
      setInfoErrors([{msg:"Nimeä tai käyttäjänimeä ei voi jättää tyhjäksi."}]);
      return;
    }

    setEditing(false);
    setInfoErrors([]);

    let settings = {
      id: props.userInfo.id,
      user: newUserInfo,
    };

    transport
      .post(`${props.baseURL}/api/save-user-settings`, settings)
      .then(response => {
        if (!response.data.success) {
          setErrorMessage({errorMessage: "Jotain meni pieleen."});
        } else {
          props.authenticate();
        }
      })
      .catch(e => {
        console.log(e)
      });
  };

  const infoErrorsDiv = () => {
    if (infoErrors.length === 0) return;
    return(
      <div className="errors">
        {infoErrors.map((e, i) => <p key={i}>{e.msg}</p>)}
      </div>
    )
  };

  const saveButton = () => (
    <button className="btn" onClick={saveSettings}>Tallenna muutokset</button>
  );

  const settings = [
    {label: "Nimi", name: "name"},
    {label: "Käyttäjänimi", name: "username"},
    {label: "Sähköpostiosoite", name: "email"},
  ];

  const showSaveButton = editing.name || editing.username || editing.email;
  return(<div>
    <hr/>
    <h3>Omat tiedot</h3>
    {infoErrorsDiv()}
    {settings.map(setting => (
      <Setting 
        key={setting.name}
        label={setting.label}
        type={setting.name}
        newValue={newUserInfo[setting.name]}
        value={props.userInfo[setting.name]}
        onChange={e => handleSettingChange(e, setting.name)}
        editing={editing}
      />
    ))}
    {editing ?
      <div className="buttons-row">{[saveButton(), cancelButton()]}</div>
    : editButton()}
    {showSaveButton && saveButton()}
    <hr/>
  </div>);
};

const LoggedInSettings = (props) => {
  return(<div>
    <UserInfoSettings
      baseURL={props.baseURL}
      userInfo={props.userInfo}
    />
    <ChangePasswordForm 
      baseURL={props.baseURL}
      userInfo={props.userInfo}
    />
  </div>);
};

export default function SettingsPage(props) {
	
  const [ loading, setLoading ] = useState(true);

  const { authenticate, changeTheme, loggedIn, baseURL, userInfo } = useOutletContext();

  useEffect(() => {
    document.title = props.title ? props.title + " | Coding Club" : "Coding Club"
    authenticate().then(() => {
      setLoading(false);
    });
  }, []);

  const themeButton = (theme) => (
    <button
      className="themebutton"
      key={theme}
      onClick={() => changeTheme(theme)}
    >
      <div data-theme={theme}>
      <header className={headerCSS.appHeader}>
        <h1 className={headerCSS.siteName}>
          <span className={headerCSS.link}>Coding Club</span>
        </h1>
      </header>
      <div className="content">
      <h2>{theme}</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
      </div>
    </div></button>
  );

  const themeSettings = () => {
    const themes = ["dark","light","pink-light"];
    return(
      <div>
        <h3>Teema</h3>
        <div className="themebuttons">
          {themes.map(theme => themeButton(theme))}
        </div>
      </div>
    );
  };

  return(
    <div className="content">
      <h2>Asetukset</h2>
      { !loading && <div>
        {themeSettings()}
        {loggedIn &&
          <LoggedInSettings
            baseURL={baseURL}
            userInfo={userInfo}
          />
        }
      </div>}
    </div>
  );
}
