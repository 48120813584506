import { useState, useEffect } from "react";
import transport from './transport.js'
import { useNavigate, useParams, useOutletContext } from 'react-router-dom'

export default function UserPage(props) {
  const { id } = useParams();
  const navigate = useNavigate();

  const [ user, setUser ] = useState({});
  const [ newInfo, setNewInfo ] = useState({});
  const [ editing, setEditing ] = useState(false);
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(null);
  const [ inputErrors, setInputErrors ] = useState([]);
  const [ passwordErrors, setPasswordErrors ] = useState([]);

  const { authenticate, baseURL, notify, userInfo } = useOutletContext();

  const roles = ["basic", "utu-student", "cc-member", "editor", "admin"];

  useEffect(() => {
    document.title = props.title ? props.title + " | Coding Club" : "Coding Club"
    authenticate();
  }, []);

  useEffect(() => {
    transport.get(`${baseURL}/api/user/${id}`)
    .then(res => {
      setUser({...res.data});
      setNewInfo({...res.data});
      setLoading(false);
    })
    .catch(e => {
      if (e.response.status === 403) {
        navigate("/");
      }
      console.log(e);
      setError(e);
    })
  }, []);

  const handleError = e => {
    console.log(e);
    setError(e);
  };

  const errors = () => {
    if (!inputErrors.length) return;
    return(
      <div className="errors">
        {inputErrors.map((e, i) => <p key={i}>{e.msg}</p>)}
      </div>
    )
  };

  const cancel = () => {
    setEditing(false);
    setNewInfo({...user});
  };

  const handleInputChange = (event, type) => {
    let newNewInfo = {...newInfo};
    newNewInfo[type] = event.target.value;
    setNewInfo(newNewInfo);
  };

  const infoRow = (label, data, type) => {
    if (editing && type) {
      let input = <input value={newInfo[type]}
                  onChange={(e) => handleInputChange(e, type)} />
      if (type === "role") {
          input = <select value={newInfo[type]} onChange={(e) => handleInputChange(e, type)}>
            { roles.map(r =>
              <option key={r} value={r}>{r}</option>)
            }
          </select>
      }
      return (
        <tr>
          <td>{label}:</td>
          <td>{input}</td>
        </tr>
      );
    }
    return (
      <tr>
        <td>{label}:</td>
        <td>{data}</td>
      </tr>
    );
  };

  const userInfoTable = () => (
    <div>
      <table><tbody>
        {infoRow("ID", user._id)}
        {infoRow("Nimi", user.name, "name")}
        {infoRow("Käyttäjänimi", user.username, "username")}
        {infoRow("Sähköposti", user.email, "email")}
        {infoRow("Rooli", user.role, "role")}
        {infoRow("Luotu", new Date(user.dateCreated).toLocaleDateString("fi"))}
      </tbody></table>
    </div>
  );
  
  const changePassword = (event) => {
    event.preventDefault()

    setPasswordErrors([]);

    const passwordForm = {
      _id: user._id,
      newPassword: document.getElementById("newpassword").value,
      newPassword2: document.getElementById("newpassword2").value,
    }

    if (!passwordForm.newPassword || !passwordForm.newPassword2) {
      setPasswordErrors([{msg: "Salasanaa ei voi jättää tyhjäksi"}]);
      return;
    }

    if (passwordForm.newPassword !== passwordForm.newPassword2) {
      setPasswordErrors([{msg: "Salasanat eivät ole samat"}]);
      return;
    }

    transport.post(`${baseURL}/api/change-password`, passwordForm)
    .then(response => {
      if (response.data.success) {
        notify("Salasana vaihdettu");
      } else {
        setPasswordErrors(response.data.errors);
      }
    })
    .catch(handleError);
  };

  const passwordErrorsDiv = () => {
    if (!passwordErrors.length) return;
    return(<div className="errors">
      {passwordErrors.map(error =>
          <p key={error.msg}>{error.msg}</p>
      )}
    </div>)
  };

  const changePasswordForm = () => (
    <div>
      <h3>Vaihda salasana</h3>
      {passwordErrorsDiv()}
      <form className="changepasswordform" onSubmit={changePassword}>
        <p className="setting">
        <label>Uusi salasana:</label><input type="password" id="newpassword"></input></p>
        <p className="setting">
          <label>Toista uusi salasana:</label>
          <input type="password" id="newpassword2"></input></p>
        <button type="submit" className="btn">Vaihda salasana</button>
      </form>
    </div>
  );

  const deleteUserSection = () => {
    if (user._id === userInfo.id) return;
    return(<div>
      <h3>Poista käyttäjä</h3>
      <button className="btn" onClick={deleteUser}>Poista käyttäjä</button>
    </div>)
  };

  const deleteUser = () => {
    if (!window.confirm(`Haluatko varmasti poistaa käyttäjän ${user.username}?`)) {
      return;
    }
    let data = { _id: user._id }
    transport.delete(`${baseURL}/api/delete-user`, {data: data})
    .then(res => {
      if (res.data.success) {
        navigate("/kayttajat");
      }
    })
    .catch(handleError);
  };

  const saveUser = () => {
    setInputErrors([]);

    let data = {
        _id: user._id,
        user: { ...newInfo },
    }
    if (!data.user.name.trim() || !data.user.username.trim() || !data.user.role.trim()) {
        setInputErrors([{msg: "Jokin kenttä puuttuu"}]);
        return
    }
    if (!roles.includes(data.user.role)) {
        setInputErrors([{msg: "Roolia ei ole olemassa"}]);
        return;
    }
    transport.post(`${baseURL}/api/save-user-settings`, data)
    .then(res => {
        if (!res.data.success) {
          setInputErrors(res.data.errors);
        } else {
          setEditing(false);
          notify("Käyttäjä tallennettu");
          setUser({...newInfo});
        }
    })
    .catch(handleError);
  }

  const saveButton = () => (
    <button className="btn" onClick={saveUser}>
        Tallenna
    </button>
  );

  const editButton = () => (
    <button className="btn" onClick={() => setEditing(true)}>Muokkaa</button>
  );

  const cancelButton = () => (
    <button className="btn" onClick={cancel}>Peruuta</button>
  );

  if (loading) return "";

  return(
    <div className="content">
      <div className="page-header">
        <h2>Käyttäjän asetukset</h2>
      </div>
      {errors()}
      {userInfoTable()}
      <p>{editing ? <>{saveButton()}{cancelButton()}</> : editButton()}</p>
      <hr/>
      {changePasswordForm()}
      <hr/>
      {deleteUserSection()}
		</div>
  );
}
