import { useEffect, useState } from 'react';
import {
  Outlet,
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

import Header from './components/Header.js'
import transport from './transport.js'
import Notification from './components/Notification';

let baseURL = ""
if (process.env.NODE_ENV !== 'production') {
  baseURL = "https://codingclub.utu.fi/test"
}

export default function App(props) {

  const [ loggedIn, setLoggedIn ] = useState(false);
  const [ loading, setLoading ] = useState(true);
  const [ theme, setTheme ] = useState("dark");
  const [ userInfo, setUserInfo ] = useState({});
  const [ notifications, setNotifications ] = useState([]);
  const [ menuPages, setMenuPages ] = useState([]);
  
  useEffect(() => {
    authenticate()
    .then(result => {
      getMenuPages()
      .then(res => {
        setLoading(false);
      });
    });

    if (localStorage) {
      const theme = localStorage.getItem("theme");
      if (theme) {
        setTheme(theme);
      }
    }
  }, []);

  const changeTheme = (theme) => {
    setTheme(theme);
    if (!localStorage) return;
    localStorage.setItem("theme", theme);
  };

  const authenticate = () => {
    let p = transport.get(`${baseURL}/api/userinfo`)
    p.then(res => {
      if (res.data.authenticated) {
        setLoggedIn(true);
        setUserInfo(res.data);
      } else {
        setLoggedIn(false);
        setUserInfo({});
      }
    })
    return p;
  };

  const getMenuPages = () => {
    let p = transport.get(`${baseURL}/api/pages`);
    p.then(res => {
      setMenuPages(res.data);
    })
    .catch(e => {
      console.log(e);
    });
    return p;
  };

  const login = (loginForm) => {
    let p = transport.post(`${baseURL}/api/login`, loginForm)
    p.then(res => {
      if (res.data.authenticated) {
        setLoggedIn(true);
        setUserInfo(res.data);
        getMenuPages();
      }
    })
    .catch(error => {
      if (error.response.status !== 401) console.log(error);
    });
    return p;
  };

  const logOut = () => {
    transport
    .post(`${baseURL}/api/logout`)
    .then(res => {
      //this.setState({loggedIn: false, userInfo: {}})

      // reload because when you log out it feels really weird 
      // if the page doesnt reload
      window.location.reload()
    })
  };

  const frontPage = {
    title: "Etusivu",
    url: "/",
  };

  const newsPage = {
    title: "Uutiset",
    url: "/uutiset",
  };

  const projectsPage = {
    title: "Projektit",
    url: "/projektit",
  };

  /*gamesPage = () => ({
    title: "Pelit",
    url: "/pelit",
  });*/
  
  const settingsPage = {
    title: "Asetukset",
    url: "/asetukset",
  };

  /*registerPage = () => ({
    title: "Luo käyttäjä",
    url: "/luo-kayttaja",
  });*/

  const loginPage = {
    title: "Kirjaudu",
    url: "/kirjaudu",
  };
  
  const logoutLink = {
    title: "Kirjaudu ulos",
    noActive: true,
    url: "/",
    onClick: logOut
  };

  const usersPage = {
    title: "Käyttäjät",
    url: "/kayttajat",
  };

  /*electronicsClubPage = () => ({
    title: "Elektroniikkakerho",
    url: "/elektroniikka",
  });*/

  const notify = (title, text = "") => {
    let newID = 0;
    if (notifications.length > 0) {
        newID = notifications.reduce((max, obj) => (
            obj.id > max.id? obj : max)).id + 1;
    }
    const newNotification = {
        id: newID,
        title: title,
        text: text
    };
    let newList = [...notifications, newNotification];
    setNotifications(newList);
  };

  const deleteNotification = (id) => {
    setNotifications(notifications.filter( n => n.id !== id ));
  };

  const notificationsDiv = () => (
    <div className="notifications">
      {notifications.map(n => 
        <Notification
          key={n.id}
          title={n.title}
          text={n.text}
          deleteFunction={() => deleteNotification(n.id)} />
      )}
    </div>
  );

  const notLoggedInLinks = [frontPage, newsPage, projectsPage, settingsPage, loginPage]
  const loggedInLinks = [frontPage, newsPage, projectsPage, settingsPage, logoutLink]
  if (userInfo.canViewUsers) {
      loggedInLinks.splice(3, 0, usersPage)
  }

  const outletContext = {
    authenticate: authenticate,
    loggedIn: loggedIn,
    userInfo: userInfo,
    menuPages: menuPages,
    getMenuPages: getMenuPages,
    notify: notify,
    baseURL: baseURL,
    changeTheme: changeTheme,
    login: login,
  };

  return(
    <div className="App" data-theme={theme}>
      {notificationsDiv()}
      <Header
        authenticate={authenticate}
        loggedIn={loggedIn}
        baseURL={baseURL}
        loggedInLinks={loggedInLinks}
        notLoggedInLinks={notLoggedInLinks}
        menuPages={menuPages}
        userInfo={userInfo}
      />
      <Outlet context={outletContext} />
    </div>
  );
}
