import React from "react";

class TicTacToe extends React.Component{      
    
    constructor(props) {
        super(props)
        this.startState = {view:this.menu()}
        this.state= Object.assign({},this.startState)
    }
    
    resetGame() {
        this.setState(Object.assign({},this.startState))
    }
    
    singlePlayerGame() {
        this.setState({view:<SingleplayerTicTacToe 
                                resetGame={() => this.resetGame()} 
                                baseURL = {this.props.baseURL}
                                />})
    }
    
    hostMultiplayer() {
        this.setState({view: <MultiplayerTicTacToe 
                                resetGame={() => this.resetGame()} 
                                character="X"
                                baseURL = {this.props.baseURL}
                                />})
    }
    
    joinMultiplayer() {
        this.setState({view: <MultiplayerTicTacToe 
                                resetGame={() => this.resetGame()} 
                                character="O"
                                baseURL = {this.props.baseURL}
                                />})
    }
    
    menu() {
        return(<div className="game-buttons">
			<button className="btn" onClick={()=>this.singlePlayerGame()}>Start game (AI)</button>
			<button className="btn" onClick={()=>this.hostMultiplayer()}>Start game (person)</button>
			<button className="btn" onClick={()=>this.joinMultiplayer()}>Join game</button>
		</div>)
    }
    
	render() {
        return(this.state['view'])
	}
}

class SingleplayerTicTacToe extends React.Component {
    constructor(props){
		super(props);
        this.state = {
            game: {},
            running: false
        }
	}    

	board() {
        var i = 0;
		return(
        <table className="tictactoe-grid"><tbody>
            <tr>
                {this.cell(i++)}{this.cell(i++)}{this.cell(i++)}
            </tr>
            <tr>
                {this.cell(i++)}{this.cell(i++)}{this.cell(i++)}
            </tr>
            <tr>
                {this.cell(i++)}{this.cell(i++)}{this.cell(i++)}
            </tr>
        </tbody></table>)
	}

    checkWin(character) {
        var wins = [[0,1,2],
        [3,4,5],
        [6,7,8],
        [0,4,8],
        [2,4,6],
        [0,3,6],
        [1,4,7],
        [2,5,8]];
        
        var indexIsChar = (cell) => this.state['game'][cell]===character;
        var rowWin = (line) => line.filter(indexIsChar);
        wins = wins.map(rowWin)
        wins = wins.filter( line => line.length === 3);
        return wins.length > 0;
    }
    
    setCell(index, character){
        var game = this.state['game'];
        game[index] = character;
        this.setState({'game':game});
        
        var chars = new Set();
        Object.values(game).forEach(e => chars.add(e));
        chars = [...chars].map(e => [e,this.checkWin(e)]);        
        chars = chars.filter(e => e[1])
        
        if (chars.length > 0) {
            this.setState({'winner':chars[0][0]});
            return;
        }
                
        var freeIndex = [0,1,2,3,4,5,6,7,8].filter(e => Object.keys(game).indexOf(e.toString())<0);
        freeIndex = freeIndex[Math.floor(Math.random() * freeIndex.length)];
        if(Object.keys(game).length%2===1) this.setCell(freeIndex, "O");        
        
    }
    
	cell(cellIndex) {
		return (<td key={cellIndex} onClick={() => this.setCell(cellIndex, 'X')}>
            {this.state['game'][cellIndex] || " "}
        </td>)
	}

    gameEnd() {
        return (<div>
            <p>{this.state['winner']} voitti!</p>
            <p className="content-link" onClick={ () => this.props.resetGame()}>
                Paina tästä aloittaaksesi uuden pelin alusta
            </p>
        </div>)
    }
    
	render() {
        return (<div style={{fontFamily:"Courier New"}}>
            {this.board()}
            {this.state.winner ? this.gameEnd() : ""}
        </div>)
	}
}

class MultiplayerTicTacToe extends React.Component{

    constructor(props) {
		super(props)
        this.state = {}
        this.loading = false
	}  
    
    componentDidMount(){
        if (this.props.character === "X" && !this.loading){
            this.multiplayerGameHost()
        }
        if (this.props.character === "O" && !this.loading){
            this.multiplayerGameJoin()
        }
    }
    
    componentWillUnmount(){
        clearInterval(this.query || 0)
    }
    
    post(endpoint, body, callback){
		if (typeof body === 'object')
			body = JSON.stringify(body);
		
		fetch(`${this.props.baseURL}${endpoint}`, {
		  method: 'POST',
		  headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		  },
		  body: body
		})
		.then(response => response.json())
		.then(response => callback(response));
	}
    
    get(endpoint, callback) {		
		fetch(`${this.props.baseURL}${endpoint}`)
		.then(response => response.json())
		.then(response => callback(response))
	}
    
    multiplayerGameJoin() {
        var id;
        
        const handleSubmit = (event) => {
            event.preventDefault();          
          
            this.post("/api/tictactoe/join",{'gameID':id}, (res) => {
                this.setState(res);                
            
                this.query = setInterval(() => this.get('/api/tictactoe/query/'+id, (res) => {                    
                    this.setState(res);   
                    this.setState({'view':this.board()});                    
                }), 1000);
            });
        }

        this.setState({view: (
          <form onSubmit={handleSubmit}>
            <label>Enter game id:
              <input 
                type="text" 
                value={id}
                onChange={(e) => {id = e.target.value}}
              />
            </label>
            <button class="btn" type="submit">Submit</button>
          </form>
        )})
  
        
    }
    
    multiplayerGameHost(){
        this.loading = true;
        this.post('/api/tictactoe/start',{}, (res) => {
            this.setState({...res})
            this.loading = false
            
            this.query = setInterval(() => this.get('/api/tictactoe/query/'+res['gameID'], 
            (res) => {
                this.setState(res);
                this.setState({'view':this.board()})
            }), 1000)
        })
        
                
    }
    
    board() {
        var i = 0
		return(
		<div style={{fontFamily:"Courier New"}}>
            <table className="tictactoe-grid"><tbody>
            <tr>{this.cell(i++)}{this.cell(i++)}{this.cell(i++)}</tr>
            <tr>{this.cell(i++)}{this.cell(i++)}{this.cell(i++)}</tr>
            <tr>{this.cell(i++)}{this.cell(i++)}{this.cell(i++)}</tr>
            </tbody></table>

            <p> GAME ID : {this.state['gameID']}</p>
            <p>{this.state['winner'] && "Voittaja: "+ this.state['winner']+"!"}</p>
            <p className="content-link" onClick={() => this.props.resetGame()}>
                {this.state['winner'] && "Klikkaa tästä aloittaaksesi uudestaan"}</p>
		</div>)
	}
    
    setCell(index){
        var data = Object.assign({},this.state)
        delete data['view']
        this.post('/api/tictactoe/move',{...data,'index':index},() => {})
    }
    
	cell(cellIndex){
		return (<td key={cellIndex} onClick={() => this.setCell(cellIndex)}>
            {this.state['game'][cellIndex] || " "}
        </td>)
	}
    
    render(){
        return (this.state['view'])
    }
}
export default TicTacToe
