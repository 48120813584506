import {
  NavLink, Link
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical as Ellipsis } from '@fortawesome/free-solid-svg-icons';
import { faPen as Pen } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import css from './Header.module.css';

export default function Header(props) {
  const [showingPageMenu, setShowingPageMenu] = useState(false);

  const navLinks = () => (
    props.loggedIn ? props.loggedInLinks : props.notLoggedInLinks
  )

  const navLink = (page) => {
    return (                 
      <CloseMenuNavLink
        className={nav => (nav.isActive && !page.noActive ? "nav-link nav-link-active" : "nav-link")} to={page.url} onClick={page.onClick} end key={page.title}>
        {page.title}
      </CloseMenuNavLink>);
  };

  const CloseMenuLink = (props) => (
    <Link {...props} onClick={() => setShowingPageMenu(false)}>
      {props.children}
    </Link>
  );

  const CloseMenuNavLink = (props) => (
    <NavLink {...props}
        onClick={() => {
          setShowingPageMenu(false);
          if (props.onClick) { props.onClick();}}}>
      {props.children}
    </NavLink>
  );

  const menuPage = (p) => {
    return( <div className={css.page} key={p.id}>
      { p.path ?
        <CloseMenuLink to={`/sivut/${p.path}`}>{p.title}</CloseMenuLink> :
        <span>{p.title}</span>
      }
      { ((props.loggedIn && props.userInfo.canDeleteAny.page) || props.userInfo.id === p.creator) &&
        <CloseMenuLink to={`/sivut/${p.id}/muokkaa`}><FontAwesomeIcon icon={Pen} /></CloseMenuLink>
      }
    </div>);
  };

  const pagesMenu = () => {
    if (!props.menuPages && !props.userInfo.canCreatePages) return "";
    return(<div className={css.menuContainer}>
      <button className={css.menuButton} onClick={() => setShowingPageMenu(!showingPageMenu)}>
        <FontAwesomeIcon icon={Ellipsis} />
      </button>
      <div className={css.menu} style={showingPageMenu ? {} : {display: "none"}}>
        {
          props.menuPages && props.menuPages.map(p => menuPage(p))
        }
        { !props.loggedIn && props.menuPages === [] &&
        <div className={css.noPages}>
          Valikko on tyhjä.
        </div>
        }
        { props.userInfo.canCreatePages &&
        <CloseMenuLink to="/lisaa-sivu" className={css.addPageLink}>
        <div className={css.page}>
          Uusi sivu +
        </div>
        </CloseMenuLink>}
      </div>
    </div>);
  }
  
  return(
    <header className={css.appHeader}>
      <h1 className={css.siteName}>
        <a className={css.link} href="/">Coding Club</a>
      </h1>
    
      <nav className={css.links}>
        {pagesMenu()}
        {navLinks().map(l => navLink(l))}
      </nav>
    </header>
  );
}
